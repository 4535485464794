import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

// assets
import solutionIcon from 'assets/img/recycletek-solution-icon.png';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(16),
    marginBottom: theme.spacing(8),
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    '& h3': {
      marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-end',
    },
  },
  solutionIcon: {
    height: '55px',
    width: 'auto',
    opacity: '0.7',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      height: '45px',
      marginLeft: theme.spacing(1),
    },
  },
}));

const SectionHeader = ({ text }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography variant="h4">{t(text)}</Typography>
      <img
        className={classes.solutionIcon}
        src={solutionIcon}
        alt="header icon"
      />
    </Box>
  );
};

export default SectionHeader;
