import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation, Trans } from 'react-i18next';

// @material-ui/core components
import {
  Container,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// components;
import Page from 'components/Page/Page';
import SEOTags from 'components/SEOTags/SEOTags';
import InfoCard from 'components/InfoCard/InfoCard';
import Section from 'components/Page/Section';
import SectionHeader from 'components/Header/SectionHeader';

// assets
import ecoPodXP from 'assets/img/eco-pod-xp.png';
import ecoPod from 'assets/img/eco-pod-alt.png';
import ecoMobile from 'assets/img/eco-mobile.png';
import ecoCount from 'assets/img/ecocount-machine.png';
import ecoCash from 'assets/img/eco-cash.png';
import sortMachine from 'assets/img/anker-andersen-sort.png';
import rocketIcon from 'assets/img/rocket-icon.png';
import transparencyIcon from 'assets/img/transparency-icon.png';
import lightbulbIcon from 'assets/img/lightbulb-icon.png';
import dataGraphic from 'assets/img/data-graphic.png';
import recycletekProductsBackofficeImage from 'assets/img/recycletek_products_backoffice.png';
import recycletekProductsCustomerPortalImage from 'assets/img/recycletek_products_portal.png';
import recycletekProductsUIImage from 'assets/img/recycletek_products_ui.png';
import ecoCountRainbow from 'assets/img/recycletek-ecocount-rainbow.jpg';

// spec-sheets
import datasheetEcocount3 from 'assets/datasheets/EcoCount 3 - Datasheet.pdf';
import datasheetEcopod from 'assets/datasheets/EcoPod - Sales Sheet (Version B).pdf';
import datasheetEcomobileXp from 'assets/datasheets/EcoMobile XP - Datasheet.pdf';
import datasheetEcocash from 'assets/datasheets/EcoCash Sales Sheet.pdf';

const useStyles = makeStyles((theme) => ({
  ecoCountImg: {
    width: '70%',
    minWidth: '200px',
    height: 'auto',
    clipPath: 'circle()',
  },
  center: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  cta: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  card: {
    maxWidth: '580px',
    minWidth: '580px',
    minHeight: '120px',
    '& img': {
      height: '180px',
      width: 'auto',
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'flex',
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '440px',
      minWidth: '440px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '300px',
      minWidth: '300px',
    },
  },
  cardContent: {
    minHeight: '375px',
    [theme.breakpoints.down('md')]: {
      minHeight: '400px',
    },
  },
  cardFooter: {
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  narrowCard: {
    maxWidth: '330px',
    minWidth: '330px',
    minHeight: '120px',
    '& img': {
      width: '100%',
      height: 'auto',
      marginTop: theme.spacing(1),
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '280px',
      minWidth: '280px',
    },
  },
  highlights: {
    width: '100%',
    justifyContent: 'space-evenly',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
  },
  highlight: {
    textAlign: 'center',
    minWidth: '240px',
    maxWidth: '240px',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6),
    },
  },
  textImage: {
    width: '95%',
    height: 'auto',
    minWidth: '300px',
  },
}));

const Products = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const products = [
    {
      title: t('products.product.one.title'),
      image: ecoCount,
      points: [
        t('products.product.one.points.p1'),
        t('products.product.one.points.p2'),
        t('products.product.one.points.p3'),
      ],
      buttonLink: '/contact',
      buttonText: 'Get a Quote',
      datasheetLink: datasheetEcocount3,
    },
    {
      title: t('products.product.two.title'),
      image: ecoMobile,
      points: [
        t('products.product.two.points.p1'),
        t('products.product.two.points.p2'),
        t('products.product.two.points.p3'),
        t('products.product.two.points.p4'),
      ],
      buttonLink: '/contact',
      buttonText: 'Get a Quote',
      datasheetLink: datasheetEcomobileXp,
    },
    {
      title: t('products.product.three.title'),
      image: ecoPod,
      points: [
        t('products.product.three.points.p1'),
        t('products.product.three.points.p2'),
        t('products.product.three.points.p3'),
        t('products.product.three.points.p4'),
      ],
      buttonLink: '/contact',
      buttonText: 'Get a Quote',
      datasheetLink: datasheetEcopod,
    },
    {
      title: t('products.product.four.title'),
      image: ecoCash,
      points: [
        t('products.product.four.points.p1'),
        t('products.product.four.points.p2'),
        t('products.product.four.points.p3'),
        t('products.product.four.points.p4'),
      ],
      buttonLink: '/contact',
      buttonText: 'Get a Quote',
      datasheetLink: datasheetEcocash,
    },
    {
      title: t('products.product.five.title'),
      image: ecoPodXP,
      points: [
        t('products.product.five.points.p1'),
        t('products.product.five.points.p2'),
        t('products.product.five.points.p3'),
      ],
      buttonLink: '/contact',
      buttonText: 'Get a Quote',
    },
    {
      title: t('products.product.six.title'),
      image: sortMachine,
      points: [
        t('products.product.six.points.p1'),
        t('products.product.six.points.p3'),
        t('products.product.six.points.p4'),
        t('products.product.six.points.p5'),
      ],
      buttonLink: '/contact',
      buttonText: 'Get a Quote',
    },
  ];

  const highlights = [
    {
      text: t('products.highlights.one'),
      image: transparencyIcon,
    },
    {
      text: t('products.highlights.two'),
      image: rocketIcon,
    },
    {
      text: t('products.highlights.three'),
      image: lightbulbIcon,
    },
  ];

  const softwares = [
    {
      title: t('products.software.one.title'),
      image: recycletekProductsUIImage,
      points: [
        t('products.software.one.points.p1'),
        t('products.software.one.points.p2'),
        t('products.software.one.points.p3'),
        t('products.software.one.points.p4'),
      ],
    },
    {
      title: t('products.software.two.title'),
      image: recycletekProductsBackofficeImage,
      points: [
        t('products.software.two.points.p1'),
        t('products.software.two.points.p2'),
        t('products.software.two.points.p3'),
        t('products.software.two.points.p4'),
      ],
    },
    {
      title: t('products.software.three.title'),
      image: recycletekProductsCustomerPortalImage,
      points: [
        t('products.software.three.points.p1'),
        t('products.software.three.points.p2'),
        t('products.software.three.points.p3'),
        t('products.software.three.points.p4'),
      ],
    },
  ];

  const handleClick = (link) => {
    history.push(link);
  };

  return (
    <>
      <SEOTags
        title="Recycletek Products"
        description="View and learn about Recycletek products that can change the way you, or your business recycles."
      />
      <Page>
        <Container maxWidth="lg">
          <Section textWithImage noMarginTop>
            <Box>
              <Typography variant="h2">{t('products.driveSuccess')}</Typography>
              <Typography variant="body2">
                <Trans>{t('products.driveSuccessParagraph')}</Trans>
              </Typography>
            </Box>
            <Box>
              <img
                className={classes.ecoCountImg}
                src={ecoCountRainbow}
                alt="scenic product photo"
              />
            </Box>
          </Section>
          <SectionHeader text="products.products" />
          <Section noMarginTop>
            <Box className={classes.cards}>
              {products.map((product) => (
                <InfoCard reducedPadding heading={product.title}>
                  <Box className={classes.card}>
                    <Box className={classes.cardContent}>
                      <List dense>
                        {product.points.map((point) => (
                          <ListItem>
                            <ListItemIcon>
                              <CheckCircleIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText variant="h4" primary={point} />
                          </ListItem>
                        ))}
                      </List>
                      <img src={product.image} alt="product image" />
                    </Box>
                    <Box className={classes.cardFooter}>
                      {product.buttonLink && (
                        <Button onClick={() => handleClick(product.buttonLink)}>
                          {product.buttonText}
                        </Button>
                      )}
                      {product.datasheetLink && (
                        <Link target="_blank" to={product.datasheetLink}>
                          {t('products.specSheet')}
                        </Link>
                      )}
                    </Box>
                  </Box>
                </InfoCard>
              ))}
            </Box>
          </Section>
          <Section>
            <Box className={classes.highlights}>
              {highlights.map((highlight) => (
                <Box className={classes.highlight}>
                  <img src={highlight.image} />
                  <Typography variant="body2">{highlight.text}</Typography>
                </Box>
              ))}
            </Box>
          </Section>
          <SectionHeader text="products.softwareEcoSystem" />
          <Section noMarginTop>
            <Box className={classes.cards}>
              {softwares.map((software) => (
                <InfoCard heading={software.title}>
                  <Box className={`${classes.card} ${classes.narrowCard}`}>
                    <List dense>
                      {software.points.map((point) => (
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircleIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText variant="h4" primary={point} />
                        </ListItem>
                      ))}
                    </List>
                    <img src={software.image} alt="product image" />
                  </Box>
                </InfoCard>
              ))}
            </Box>
          </Section>
          <Section textWithImage>
            <Box>
              <Typography variant="h2">
                {t('products.improveOperations')}
              </Typography>
              <Typography variant="body">
                <Trans>{t('products.improveOperationsParagraph')}</Trans>
              </Typography>
            </Box>
            <Box>
              <img
                className={classes.textImage}
                src={dataGraphic}
                alt="Measurable Results"
              />
            </Box>
          </Section>
        </Container>
      </Page>
    </>
  );
};

export default Products;
